
(function() {
    'use strict';

    angular
        .module('osai', [
            'app.core',
            'app.bootstrapui',
            'app.routes',
            'app.sidebar',
            'app.navsearch',
            'app.preloader',
            'app.loadingbar',
            'app.translate',
            'app.settings',
            'app.utils',
            'app.forms',
            'common',
            'naturalSort'
            
        ]);


       angular.module("naturalSort",[]).factory("naturalService",["$locale",function(a){"use strict";var b=function(a){return"00000000000000000000".slice(a.length)},c=function(a){return null===a||void 0===a?"":""+a},d="M"===a.DATETIME_FORMATS.shortDate.charAt(0),e=function(a){return c(a).replace(/(\d\d?)[-\/\.](\d\d?)[-\/\.](\d{4})/,function(a,b,c,e){var f=c;return d?Number(b)>12&&(c=b,b=f):Number(c)<13&&(c=b,b=f),e+"-"+b+"-"+c})},f=function(a){return a.replace(/(\d+)((\.\d+)+)?/g,function(a,c,d,e){return d!==e?a.replace(/(\d+)/g,function(a){return b(a)+a}):(d=d||".0",b(c)+c+d+b(d))})},g=function(a){return f(e(a))};return{naturalValue:g,naturalSort:function(a,b){return a=natVale(a),b=g(b),b>a?-1:a>b?1:0}}}]).run(["$rootScope","naturalService",function(a,b){"use strict";a.natural=function(a){return function(c){return b.naturalValue(c[a])}}}]);
})();
