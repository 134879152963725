/************************************************
* modal.controller.js
* controls what happens when modal is dismissed
************************************************/
(function() {
	'use strict';

	angular.module('common')
		.controller('ModalInstanceCtrl', function ($scope, $rootScope, $uibModalInstance, modalObject) {
			$scope.modalObject = modalObject;

			$scope.ok = function () {
				$uibModalInstance.close($scope.modalObject);
			};

			$scope.cancel = function () {
				$uibModalInstance.dismiss('cancel');
			};


		});
})();
