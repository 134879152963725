(function() {
    'use strict';
    angular.module('common')
        .directive('onHover', function() {
            return {
                restrict: 'A',
                link: function(scope, element) {
                    element.on('mouseenter', function() {
                        element.find('.showOnHover').removeClass('ng-hide');
                    });
                    element.on('mouseleave', function() {
                        element.find('.showOnHover').addClass('ng-hide');
                    });
                }
            };
        });

})();
