(function() {
    'use strict';

    angular.module('osai')
        .controller('SpecialScoresController', SpecialScoresController);

    SpecialScoresController.$inject = ['$q', '$http', '$timeout', '$state', '$sessionStorage', '$scope', '$uibModal', '$rootScope', 'DataService'];

    function SpecialScoresController($q, $http, $timeout, $state, $sessionStorage, $scope, $uibModal, $rootScope, DataService) {

        var vm = this;
        vm.selectedDiscipline = null;
        vm.selectedCandidate = null;
        vm.selectedAdjudicator = null;
        vm.savingScores = false;
         

        vm.setSelectedDiscipline = setSelectedDiscipline;
        vm.setSelectedAdjudicator = setSelectedAdjudicator;

        // vm.gotoCandidateList = gotoCandidateList; 
        vm.maybeReturnToAdjudicatorSelect = maybeReturnToAdjudicatorSelect; // verifies that scores have been saved before returning
    
        vm.prepareToSaveScores = prepareToSaveScores; // may or may not show modal confirm box
        vm.prepareToEditScores = prepareToEditScores;
        vm.evaluateStatus = evaluateStatus; 
        vm.maybeSaveScores = maybeSaveScores;
        

        activate();






        function setSelectedDiscipline(discipline_id) {
            DataService.get('Discipline', discipline_id, null).then(function(result) {
                vm.selectedDiscipline = result;
                $state.go('manager.specialScores.judgeSelect');  
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function setSelectedAdjudicator(selectedAdjudicatorNumber) {
            vm.selectedAdjudicator = selectedAdjudicatorNumber;
            $state.go('manager.specialScores.scoreEntry');
            loadSpecialCandidateList().then(function() {  
                loadCriteria().then(function() {
                    
                })
                .catch(function(error) {
                    console.log(error);
                });
                
            })
            .catch(function(error) {
                console.log(error);
            });
            // if (candidate.status == 'pending') {
            //     vm.selectedCandidate = candidate;
            //     if (vm.selectedCandidate.rawScores) {
            //         vm.selectedCandidate.rawScores=null;
            //     }
            //     vm.selectedCandidate.judges = [];
            //     for(var i=0;i<vm.numberOfJudges; i++) {
            //         var judge = {
            //             rawScores: [],
            //             judgeNum: parseInt(i)+1
            //         };
            //         vm.selectedCandidate.judges.push(judge);
            //     }
            //     $state.go('manager.specialScores.scoreEntry');
            // }     
        }




        // function gotoCandidateList() {
        //     loadSpecialCandidateList().then(function() {  
        //         loadCriteria();
        //     })
        //     .catch(function(error) {
        //         console.log(error);
        //     });
        // }


        function maybeReturnToAdjudicatorSelect() {
            // if (valid) {
            //     if (confirm('Are you sure? Scores have not been submitted.') ) {
            //         $state.go('manager.specialScores.judgeSelect');
            //     }
            // } else {
                $state.go('manager.specialScores.judgeSelect');
            // }
            
        }

        

        function prepareToSaveScores(candidate) {
            candidate.mode = 'saving';
            saveScores(candidate).then(function(result) {
                candidate.savedScores = candidate.newScores;
                candidate.mode = 'scored';
                candidate.judgeScore_id = result.judgeScore_id;
            })
            .catch(function(error) {
                candidate.saving = false;
                alert(error);
            });   
        }


        function prepareToEditScores(candidate) {
            candidate.newScores = candidate.savedScores;
            candidate.mode = 'editing';
        }

        function evaluateStatus(candidate) {
            if (candidate.savedScores) {
                candidate.mode="scored";
            } else {
                candidate.mode="pending";
            }
        }

        function maybeSaveScores(candidate, valid) {
            if (valid && candidate.mode == 'pending') {
                prepareToSaveScores(candidate);
                // candidate.savedScores = candidate.newScores;
                // candidate.mode = 'scored';
            }
        }


       
        // ******************************************* PRIVATE FUNCTIONS  *******************************************

        function activate() {
            $scope.$watch('ManagerCtrl.specialScoresDisciplineName', function(newValue) {
                var discipline_id = null;
                if (newValue == 'acting') {
                    discipline_id = 22;
                    vm.numberOfJudges = 3;
                }
                if (newValue == 'dance') {
                    discipline_id = 23;
                    vm.numberOfJudges = 4;
                }
                vm.setSelectedDiscipline(discipline_id);
            });
        }



        function loadSpecialCandidateList() {
            vm.candidateList = null;
            vm.candidatesLoading = true;
            return DataService.get('Discipline', vm.selectedDiscipline.discipline_id, 'special_candidates', vm.selectedAdjudicator, 'Candidates could not be loaded.').then(function(result) {
                vm.candidateList = result;
                vm.candidatesLoading = false;
            })
            .catch(function(error) {
                vm.candidatesLoading = false;
                console.log(error);
            });
        }


        function loadCriteria() {
            return DataService.get('Discipline', vm.selectedDiscipline.discipline_id, 'criteria', null, 'Could not load criteria').then(function(result) {
                vm.criteria = result;
            })
            .catch(function(error) {
                console.log(error);
            });
        }



        


        function saveScores(candidate) {
            var totalAdjustedScore = 0;
            var rawScoreCalc;
            var percentCalc;
            var productCalc;
            var rawScoreString = '';
            var tb1;
            var tb2;
            for(var i=0; i<vm.criteria.length; i++) {
                rawScoreCalc = candidate.newScores[i];
                percentCalc = vm.criteria[i].criteria_weight;
                productCalc = rawScoreCalc * percentCalc * 0.01;
                totalAdjustedScore = totalAdjustedScore + productCalc;
                rawScoreString = rawScoreString + rawScoreCalc.toString();
                if (i < vm.criteria.length-1) {
                    rawScoreString = rawScoreString + ',';
                }
                if (vm.selectedDiscipline.discipline_tiebreaker_primary == vm.criteria[i].criteria_id) {
                    tb1 = rawScoreCalc;
                }
                if (vm.selectedDiscipline.discipline_tiebreaker_secondary == vm.criteria[i].criteria_id) {
                    tb2 = rawScoreCalc;
                }
            }
            
            var newJudgeScore = {
                judgeScore_id: candidate.judgeScore_id,
                judgeScore_f_candidate_id: candidate.candidate_id,
                judgeScore_rawScores: rawScoreString,
                judgeScore_weightedScore: totalAdjustedScore,
                judgeScore_tb1: tb1,
                judgeScore_tb2: tb2,
                judgeScore_f_adjudicator_id:0,
                judgeScore_f_discipline_id: vm.selectedDiscipline.discipline_id,
                judgeScore_judgeNum: vm.selectedAdjudicator
            };
     
            return DataService.submitObj('JudgeScore', newJudgeScore);
        }

    }

    
})();