(function() {
    'use strict';

    angular.module('common')
        .factory('DataService', DataService);

        DataService.$inject = ['$http','$q'];

        function DataService($http, $q) {
            return {
                get: get,
                // postAction: postAction,
                // submitObject: submitObject,
                // deleteObject: deleteObject,
                submitObj: submitObj,
                deleteObj: deleteObj,
                action: action
            };

            function submitObj(className, object, error) {
                var dataArray = $.param({
                    objectClass: className,
                    object: object
                });
                var deferred = $q.defer();
                var config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                    }
                };
                $http.post('app/private/phpscripts/submitObject.php', dataArray, config)
                    .then(
                        function(response) {
                            deferred.resolve(response.data);
                        },
                        function() {
                            if (!error) {
                                error = 'Could not submit ' + className;
                            }
                            deferred.reject(error);
                        }
                    );
                return deferred.promise;
            }


            // function submitObject(dataArray, error) {
            //     var deferred = $q.defer();
            //     var config = {
            //         headers: {
            //             'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            //         }
            //     };
            //     $http.post('app/private/phpscripts/submitObject.php', dataArray, config)
            //         .then(
            //             function(response) {
            //                 deferred.resolve(response.data);
            //             },
            //             function() {
            //                 deferred.reject(error);
            //             }
            //         );
            //     return deferred.promise;
            // }


            function deleteObj(className, id, error) {
                var dataArray = $.param({
                    objectClass: className, 
                    id: id
                });
                var deferred = $q.defer();
                var config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                    }
                };
                $http.post('app/private/phpscripts/delete.php', dataArray, config)
                    .then(
                        function(response) {
                            deferred.resolve(response.data);
                        },
                        function() {
                            if (!error) {
                                error = 'Could not delete ' + className;
                            }
                            deferred.reject(error);
                        }
                    );
                return deferred.promise;
            }


            // function deleteObject(dataArray, error) {
            //     var deferred = $q.defer();
            //     var config = {
            //         headers: {
            //             'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            //         }
            //     };
            //     $http.post('app/private/phpscripts/delete.php', dataArray, config)
            //         .then(
            //             function(response) {
            //                 deferred.resolve(response.data);
            //             },
            //             function() {
            //                 deferred.reject(error);
            //             }
            //         );
            //     return deferred.promise;
            // }

            function action(className, id, action, error) {
                var dataArray = $.param({
                    objectClass: className,
                    id: id,
                    action: action
                });
                var deferred = $q.defer();
                var config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                    }
                };
                $http.post('app/private/phpscripts/action.php', dataArray, config)
                    .then(
                        function(response) {
                            deferred.resolve(response);
                        },
                        function() {
                            if (!error) {
                                error = 'Could not perform action: ' + action;
                            }
                            deferred.reject(error);
                        }
                    );
                return deferred.promise;
            }

            // function postAction(dataArray, error) {
            //     var deferred = $q.defer();
            //     var config = {
            //         headers: {
            //             'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
            //         }
            //     };
            //     $http.post('app/private/phpscripts/action.php', dataArray, config)
            //         .then(
            //             function(response) {
            //                 deferred.resolve(response);
            //             },
            //             function() {
            //                 deferred.reject(error);
            //             }
            //         );
            //     return deferred.promise;
            // }

            
            function get(objClass, id, request, param, onFail) {
                var paramString='';
                if ($.isArray(param)) {
                    for(var i=0; i<param.length; i++) {
                        paramString += '&param' + (i + 1) + '=' + param[i];
                    }
                } else {
                    paramString='&param1=' + param;
                }
                var deferred = $q.defer();
                var url = 'app/private/phpscripts/get.php?objClass=' + objClass;

                if (id) {
                    url += '&id=' + id;
                }
                if (request) {
                    url += '&request=' + request;
                }
                if (param) {
                    url += paramString;
                    // url += '&param1=' + param;
                }
                
                $http.get(url)
                    .then(
                        function(response) {
                            deferred.resolve(response.data);
                        },
                        function() {
                            if (!onFail) {
                                onFail = 'Could not load ' + request;
                            }
                            deferred.reject(onFail);
                        }
                    );
                return deferred.promise;
            }

           
        }
})();
