(function() {
    'use strict';

    angular.module('common')
        .factory('SimulatorService', SimulatorService);

        SimulatorService.$inject= ['DataService'];

    function SimulatorService(DataService) {
        var interval;
        return {
            start: start,
            stop: stop
        };

        function start() {
            interval = setInterval( go, 2000 );
			
		}

        function go() {
            collectPerformances().then(function() {
                console.log('simulator entered scores');
            });
        }

		function stop() {
			clearInterval(interval);
		}

        function collectPerformances() {
            return DataService.action('Manager', null, 'load_candidates_for_simulator');
        }

        

    }
})();
