/**=========================================================
 * Module: config.js
 * App routes and resources configuration
 =========================================================*/


 (function() {
    'use strict';

    angular.module('app.routes')
        .config(routesConfig);

    routesConfig.$inject = ['$stateProvider', '$locationProvider', '$urlRouterProvider', 'RouteHelpersProvider'];

    function routesConfig($stateProvider, $locationProvider, $urlRouterProvider, helper) {

        // Set the following to true to enable the HTML5 Mode
        // You may have to set <base> tag in index and a routing configuration in your server
        $locationProvider.html5Mode(false);

        // defaults to dashboard
        $urlRouterProvider.otherwise('/adj/login');

        //
        // Application Routes
        // -----------------------------------
        $stateProvider
        .state('adj', {
            url: '/adj',
            abstract: true,
            template: '<div id="outsideWrapper" ui-view class="wrapper" style="height:100%"></div>',
            resolve: helper.resolveFor('modernizr', 'icons', 'whirl', 'angular-ladda', 'ui.slimscroll', 'cgNotify'),
            controller: 'AdjudicatorController as AdjudCtrl'
        })
            .state('adj.login', {
                url: '/login',
                templateUrl: helper.basepath('adjudicator/login.html')
            })
            .state('adj.welcome', {
                url: '/welcome',
                templateUrl: helper.basepath('adjudicator/welcome.html')
            })
            .state('adj.certification', {
                url: '/certification',
                templateUrl: helper.basepath('adjudicator/certification.html')
            })
            .state('adj.candidateList', {
                url: '/candidates',
                templateUrl: helper.basepath('adjudicator/candidates.html')
            })
            .state('adj.scoreEntry', {
                url: '/scoreEntry',
                templateUrl: helper.basepath('adjudicator/scoreEntry.html')
            })
        .state('manager', {
            url: '/manager',
            abstract: true,
            template: '<div ui-view class="wrapper" style="height:100%"></div>',
            resolve: helper.resolveFor('modernizr', 'icons', 'xeditable', 'localytics.directives',  'whirl', 'chart.js', 'textAngular'),
            controller: 'ManagerController as ManagerCtrl'
        })
            .state('manager.login', {
                url: '/login',
                templateUrl: helper.basepath('manager/login.html')
            })
            .state('manager.welcome', {
                url: '/welcome',
                templateUrl: helper.basepath('manager/welcome.html')
            })
            .state('manager.editAdjudicators', {
                url: '/editAdjudicators',
                templateUrl: helper.basepath('manager/editAdjudicators.html')
            })
            .state('manager.assignAdjudicators', {
                url: '/assignAdjudicators',
                templateUrl: helper.basepath('manager/assignAdjudicators.html')
            })
            .state('manager.editCertifications', {
                url: '/editCertifications',
                templateUrl: helper.basepath('manager/editCertifications.html')
            })
            .state('manager.editCriteria', {
                url: '/editCriteria',
                templateUrl: helper.basepath('manager/editCriteria.html')
            })
            .state('manager.progress', {
                url: '/progress',
                templateUrl: helper.basepath('manager/progress.html')
            })
            .state('manager.charts', {
                url: '/charts',
                templateUrl: helper.basepath('manager/charts.html')
            })
            .state('manager.photography', {
                url: '/photography',
                templateUrl: helper.basepath('manager/photography.html')
            })
            .state('manager.specialScores', {
                url: '/specialScores',
                abstract: true,
                template: '<div ui-view class="wrapper" style="height:100%"></div>',
                controller: 'SpecialScoresController as SpecialScoresCtrl'
            })
                .state('manager.specialScores.judgeSelect', {
                    url: '/candidates',
                    templateUrl: helper.basepath('manager/specialScores/judgeSelect.html')
                })
                .state('manager.specialScores.scoreEntry', {
                    url: '/scoreEntry',
                    templateUrl: helper.basepath('manager/specialScores/scoreEntry.html')
                })
        ;

    } // routesConfig

})();
