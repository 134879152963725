(function() {
    'use strict';

    angular.module('osai')
        .controller('AdjudicatorController', AdjudicatorController);

    AdjudicatorController.$inject = ['$q', '$http', '$timeout', '$state', '$sessionStorage', '$scope', '$uibModal', 'notify', 'DataService'];

    function AdjudicatorController($q, $http, $timeout, $state, $sessionStorage, $scope, $uibModal, notify, DataService) {

        var vm = this;
        vm.candidateList = null;
        vm.selectedDiscipline = null;
        vm.selectedCandidate = null;
        vm.savingScores = false;
        vm.selectedPanel = 0; //certification panel
        vm.autoAdvance = true;
        vm.candidateIndex = null; // for auto advance
        vm.hasChanges = false;  // only applies to editing pre-existing scores
        vm.allScoresDone = false;
         
        vm.login = login;
        vm.logout = logout;

        vm.nextPanel = nextPanel; //certification panel
        vm.finish = finish;  // finish certification

        vm.setSelectedDiscipline = setSelectedDiscipline;
        vm.setSelectedCandidate = setSelectedCandidate;
        vm.resetDiscipline = resetDiscipline; // for judges with multiple disciplines

        vm.gotoManagerLogin = gotoManagerLogin;
        vm.gotoCandidateList = gotoCandidateList; 
        vm.maybeReturnToCandidateList = maybeReturnToCandidateList; // verifies that scores have been saved before returning
    
        vm.startSavingScores = startSavingScores; 
        vm.exportToFile = exportToFile;
        vm.submitFinalScores = submitFinalScores;
        

        activate();


        function login() {
            vm.authMsg = '';

            if (vm.loginForm.$valid) {
                vm.loggingIn = true;  // show spinner

                DataService.get('Adjudicator', null, 'login', [vm.adjudicator_email, vm.adjudicator_pass], 'Login error').then(function(result) {
                    var response = result[0];           
                    if (!response) {  // adjudicatorID not found
                        vm.loggingIn = false;  // hide spinner
                        vm.authMsg = 'Login and password do not match an active account.';
                    } else {   // adjudicatorID was found
                        $sessionStorage.adjudicator = response;
                        vm.adjudicator = response;
                        vm.loggingIn = false;  // hide spinner
                        if ( vm.adjudicator.disciplines.length == 1) {
                            setSelectedDiscipline(0);
                        } else {
                            $state.go('adj.welcome'); 
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });  
            } else {
                /*jshint -W106*/
                vm.loginForm.adjudicator_email.$dirty = true;
                vm.loginForm.adjudicator_pass.$dirty = true;
            }
        }

        function logout() {        
            delete $sessionStorage.adjudicator;
            vm.adjudicator_email = null;
            vm.adjudicator_pass = null;
            vm.adjudicator = null;
            $state.go('adj.login');
        }




        function nextPanel() {
            vm.selectedPanel++;
        }

        function finish() { // finish certification
            vm.selectedDiscipline.judgeAssignment_certification = 1;
            DataService.submitObj('JudgeAssignment', vm.selectedDiscipline).then(function() {
                $state.go('adj.candidateList');
                vm.gotoCandidateList();
            })
            .catch(function(error) {
                console.log(error);
            });      
        }




        function setSelectedDiscipline(idx) {
            vm.selectedDiscipline = vm.adjudicator.disciplines[idx];      
            $sessionStorage.adjudicator.selectedDiscipline = vm.selectedDiscipline;
            if (vm.selectedDiscipline.judgeAssignment_certification == 1) {
                $state.go('adj.candidateList');  
                loadCandidateList().then(function() {  
                    loadCriteria();
                })
                .catch(function(error) {
                    console.log(error);
                });
            } else {
                $state.go('adj.certification');
                loadCertifications();
                vm.selectedPanel = 0;
            }
        }


        function setSelectedCandidate(idx) { // this will only be called if the scores have not been submitted
            
            var candidate = vm.candidateList[idx];
            
            vm.selectedCandidate = candidate;
            if (candidate.status == 'pending') {
                vm.candidateIndex = idx;
                vm.selectedCandidate.rawScores = [];
            } else {
                vm.autoAdvance = false;
            }
            vm.hasChanges = false;
            $state.go('adj.scoreEntry');
        }


        function resetDiscipline() {
            $state.go('adj.welcome');
            vm.selectedDiscipline = null;
            $sessionStorage.adjudicator.selectedDiscipline = null;
        }


        function gotoManagerLogin() {
            $state.go('manager.login');
        }


        function gotoCandidateList() {
            loadCandidateList().then(function() {  
                loadCriteria();
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function maybeReturnToCandidateList(valid) {
            if ((valid && vm.selectedCandidate.status=='pending') || ( vm.hasChanges && vm.selectedCandidate.status=='scored' )) {
                if (confirm('Are you sure? Scores have not been submitted.') ) {
                    $state.go('adj.candidateList');
                }
            } else {
                $state.go('adj.candidateList');
            }
            
        }

        function submitFinalScores() {
            if (confirm('Are you sure? Scores cannot be changed after final submission.')) {
                vm.selectedDiscipline.judgeAssignment_submitted = 1;
                DataService.submitObj('JudgeAssignment', vm.selectedDiscipline).then(function() {
                    
                })
                .catch(function(error) {
                    vm.selectedDiscipline.judgeAssignment_submitted = 0;
                    console.log(error);
                });   
            }
        }

        


       
        // ******************************************* PRIVATE FUNCTIONS  *******************************************

        function activate() {
            if ($sessionStorage.adjudicator) {
                vm.adjudicator = $sessionStorage.adjudicator;
                if ($sessionStorage.adjudicator.selectedDiscipline) {
                    vm.selectedDiscipline = $sessionStorage.adjudicator.selectedDiscipline;
                    loadCandidateList().then(function() {  
                        loadCriteria();
                        $state.go('adj.candidateList');
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
                } else {
                    $state.go('adj.welcome');
                }
            } else {
                vm.adjudicator = {};
                $state.go('adj.login');
            }
            
            vm.authMsg = '';
            vm.loggingIn = false;
        } 


        function loadCertifications() {
            return DataService.get('Discipline', vm.selectedDiscipline.discipline_id, 'certification_panels', null ).then(function(result) {
                vm.certificationPanels = result;
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function loadCandidateList() {
            vm.candidateList = null;
            vm.candidatesLoading = true;
            return DataService.get('Adjudicator', vm.adjudicator.adjudicator_id, 'candidates', [vm.selectedDiscipline.discipline_id], 'Candidates could not be loaded.').then(function(result) {
                vm.candidateList = result;
                checkForCompletion();
                vm.candidatesLoading = false;
            })
            .catch(function(error) {
                vm.candidatesLoading = false;
                console.log(error);
            });
        }

        function checkForCompletion() {
            var complete = true;
            vm.candidateList.forEach(function(candidate) {
                if (candidate.status == 'pending') {
                    complete = false;
                }
            });
            vm.allScoresDone = complete;
        }


        function loadCriteria() {
            return DataService.get('Discipline', vm.selectedDiscipline.discipline_id, 'criteria', null, 'Could not load criteria').then(function(result) {
                vm.criteria = result;
            })
            .catch(function(error) {
                console.log(error);
            });
        }



        function startSavingScores() {
            vm.savingScores = true;
            saveScores().then(function(result) {
                // console.log(result);
                vm.selectedCandidate.judgeScoreID = result.judgeScore_id;
                notify({message:'Scores saved successfully.', duration:2000});
                vm.selectedCandidate.status = 'scored';
                vm.savingScores = false;
                checkForCompletion();
                if (vm.autoAdvance && vm.candidateIndex < vm.candidateList.length-1) {
                    if (vm.candidateList[vm.candidateIndex + 1].status == 'pending') {
                        setSelectedCandidate(vm.candidateIndex + 1);
                    } else {
                        $state.go('adj.candidateList');
                    }
                } else {
                    $state.go('adj.candidateList');
                }
            })
            .catch(function(error) {
                vm.savingScores = false;
                alert(error);
            });
        }


        function saveScores() {
            var totalAdjustedScore = 0;
            var rawScoreCalc;
            var percentCalc;
            var productCalc;
            var rawScoreString = '';
            var tb1;
            var tb2;
            for(var i=0; i<vm.criteria.length; i++) {
                rawScoreCalc = vm.selectedCandidate.rawScores[i];
                percentCalc = vm.criteria[i].criteria_weight;
                productCalc = rawScoreCalc * percentCalc * 0.01;
                totalAdjustedScore = totalAdjustedScore + productCalc;
                rawScoreString = rawScoreString + rawScoreCalc.toString();
                if (i < vm.criteria.length-1) {
                    rawScoreString = rawScoreString + ',';
                }
                if (vm.selectedDiscipline.discipline_tiebreaker_primary == vm.criteria[i].criteria_id) {
                    tb1 = rawScoreCalc;
                }
                if (vm.selectedDiscipline.discipline_tiebreaker_secondary == vm.criteria[i].criteria_id) {
                    tb2 = rawScoreCalc;
                }
            }
            
            var newJudgeScore = {
                judgeScore_id: vm.selectedCandidate.judgeScoreID, // incase it's already been saved and is being edited
                judgeScore_f_candidate_id: vm.selectedCandidate.candidate_id,
                judgeScore_rawScores: rawScoreString,
                judgeScore_weightedScore: totalAdjustedScore,
                judgeScore_tb1: tb1,
                judgeScore_tb2: tb2,
                judgeScore_f_adjudicator_id: vm.adjudicator.adjudicator_id,
                judgeScore_f_discipline_id: vm.selectedDiscipline.discipline_id,
                judgeScore_judgeNum: vm.selectedDiscipline.judgeAssignment_judgeNum
            };
     
            return DataService.submitObj('JudgeScore', newJudgeScore).then(function(result) {
                // alert(vm.selectedCandidate.judgeScoreID);
                // vm.selectedCandidate.judgeScoreID = result.judgeScore_id;
                return result;
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function exportToFile() {
            var csvContent = "data:text/csv;charset=utf-8,";
            
            var d = new Date();
            var now = (d.getMonth() + 1) + "_" + d.getDate() + "_" + d.getFullYear();
            var fileName = vm.selectedDiscipline.discipline_name + "_" + now + ".csv";
            var criteriaNames = [];
            vm.criteria.forEach(function(criterion) {
                criteriaNames.push(criterion.criteria_name);
            });
            var heading = 'Candidate' + ',' + criteriaNames.join(',');
            csvContent += heading + "\r\n";
            vm.candidateList.forEach(function(candidate){
            var row = candidate.candidate_osai_id + "," + candidate.rawScores.join(",");
            csvContent += row + "\r\n";
            }); 


            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", fileName);
            document.body.appendChild(link); // Required for FF

            link.click();
        }

        

    }

    
})();