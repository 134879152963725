(function() {
    'use strict';

    angular.module('osai')
        .controller('ManagerController', ManagerController);

    ManagerController.$inject = ['$q', '$http', '$state', '$sessionStorage', '$scope', '$rootScope', 'DataService', 'SimulatorService'];

    function ManagerController($q, $http, $state, $sessionStorage, $scope, $rootScope, DataService, SimulatorService) {

        var vm = this;

        vm.selectedDiscipline = null;
        vm.selectedCriteriaGroup = null; // used for editing
        vm.simulationRunning = false;
        vm.specialScoresDisciplineName = null;
       
        vm.login = login;
        vm.logout = logout;
 
        vm.returnToMenu = returnToMenu;
        vm.returnToProgress = returnToProgress;  // from charts
        vm.gotoAdjudicatorLogin = gotoAdjudicatorLogin; // switching back on login page
        
        vm.gotoPhotographyTechnical = gotoPhotographyTechnical;
        vm.saveTechnicalScore = saveTechnicalScore;

        vm.gotoAdjudicatorProgress = gotoAdjudicatorProgress;
        vm.showResultsForDiscipline = showResultsForDiscipline;

        vm.gotoEditAdjudicators = gotoEditAdjudicators;
        vm.saveAdjudicator = saveAdjudicator;
        vm.removeAdjudicator = removeAdjudicator;
        vm.newAdjudicator = newAdjudicator;
        vm.gotoAssignAdjudicators = gotoAssignAdjudicators;
        vm.setSelectedDiscipline = setSelectedDiscipline;  // for assigning adjudicators
        vm.saveAssignment = saveAssignment;

        vm.gotoEditCriteria = gotoEditCriteria;
        vm.setSelectedCriteriaGroup = setSelectedCriteriaGroup;  // for editing criteria
        vm.saveCriteria = saveCriteria;

        vm.gotoEditCertifications = gotoEditCertifications;
        vm.showCertificationEditBox = showCertificationEditBox;
        vm.saveCertificationEdits = saveCertificationEdits;

        vm.gotoSpecialScoreEntry = gotoSpecialScoreEntry;

        vm.resetCertification = resetCertification;
        
        vm.simulation = simulation;

        vm.sortPropertyName = 'candidate_osai_id';
        vm.setSortField = setSortField;
        vm.reverse = false;

        vm.judgeNames = []; // for labels

        activate();

        function setSortField(fieldName, resetOrdinalsAlso) {
            vm.sortPropertyName = fieldName;
            getRankingForTableAndChart(resetOrdinalsAlso);  // to redraw table
        }


        function login() {
            vm.authMsg = '';

            if (vm.loginForm.$valid) {
                vm.loggingIn = true;  // show spinner

                DataService.get('Manager', null, 'login', [vm.manager_login, vm.manager_pass], 'Login error').then(function(result) {
                    var response = result[0];
                    
                    if (!response) {  // managerID not found
                        vm.loggingIn = false;  // hide spinner
                        vm.authMsg = 'Login and password do not match an active account.';
                    } else {   // managerID was found
                        $scope.$storage = $sessionStorage;  // make connection to sessionStorage
                        $scope.$storage.manager = response;
                        vm.manager = response;
                        vm.loggingIn = false;  // hide spinner
                        $state.go('manager.welcome');                              
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
            } else {
                /*jshint -W106*/
                vm.loginForm.manager_login.$dirty = true;
                vm.loginForm.manager_pass.$dirty = true;
            }
        }

        function logout() {
            delete $sessionStorage.manager;
            vm.manager_login = null;
            vm.manager_pass = null;
            vm.manager = null;
            $state.go('manager.login');
        }



        function returnToMenu() { // back to main menu page
            $state.go('manager.welcome');
        }

        function returnToProgress() {  // back to progress page
            vm.selectedDiscipline = null;
            $state.go('manager.progress');
        }

        function gotoAdjudicatorLogin() { // switch from manager view to adjudicator view
            $state.go('adj.login');
        }

        function gotoSpecialScoreEntry(specDisc) {  // for acting and dance scores by manager
            vm.specialScoresDisciplineName = specDisc;
            $state.go('manager.specialScores.judgeSelect');
        }

        function resetCertification(judgeAssignment) {  // mainly for testing; resets one discipline for one judge
            judgeAssignment.judgeAssignment_certification = null;
            DataService.submitObj('JudgeAssignment', judgeAssignment).then(function() {

            })
            .catch(function(error) {
                console.log(error);
            });
        }


        
        // ******************************************* PHOTOGRAPHY ONLY  *******************************************

        function gotoPhotographyTechnical() {  // manager adds technical scores that apply to all photography candidates
            $state.go('manager.photography');
            loadPhotographyCandidates();
        }

        function saveTechnicalScore(idx, valid) {  // save photography technical scores
            if (valid || vm.photographyCandidates[idx].technical_rawScore == '' ) {
                DataService.submitObj('Technical', vm.photographyCandidates[idx]).then(function(response) {
                    vm.photographyCandidates[idx].technical_id = response.technical_id;
                })
                .catch(function(error) {
                    console.log(error);
                });
            }      
        }

        // ******************************************* FINISH  *******************************************

        function gotoAdjudicatorProgress() {  // go to progress page
            $state.go('manager.progress');
            loadProgress();
        }

        function showResultsForDiscipline(discipline) { // selects discipline, ranks, and displays
            vm.selectedDiscipline = discipline;
            checkPhotographyCandidates().then(function(response) {  // if discipline is photography, make sure technical scores have been entered
                if ((response.candidateCount == response.technicalCount) || discipline.discipline_id != 21) {
                    $state.go('manager.charts');
                    vm.sortPropertyName = 'averageRank';
                    getRankingForTableAndChart(true); // true means ordinal should be set
                } else {
                    alert('Technical scores have not been entered for all photography candidates.');
                }
            });        
        }

        function getRankingForTableAndChart(resetOrdinals) {
            DataService.get('Discipline', vm.selectedDiscipline.discipline_id, 'judge_names', null, 'Could not load judge names').then(function(result) {
                
                vm.judgeNames = result;
                console.log(vm.judgeNames);
                if (resetOrdinals) {
                    vm.ranking = true;
                    rankCandidates(vm.selectedDiscipline).then(function() {
                        loadScores(vm.selectedDiscipline).then(function(response) {
                            var chartData = response.data;
                            vm.tableScores = response.data;
                            vm.ranking = false;
                            createChart(chartData);
                        })
                        .catch(function(error) {
                            console.log(error);
                        });
                    })
                    .catch(function(error) {
                        vm.ranking = false;
                        console.log(error);
                    });
                } else { // if resetOrdinals is false, just reload the results
                    loadScores(vm.selectedDiscipline).then(function(response) {
                        var chartData = response.data;
                        vm.tableScores = response.data;
                        vm.ranking = false;
                        createChart(chartData);
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
                }
            })
            .catch(function(error) {
                console.log(error);
            });
            
        }


        // ******************************************* ADJUDICATOR SETTINGS  *******************************************

        function gotoEditAdjudicators() {
            $state.go('manager.editAdjudicators');
            loadAdjudicators();
        }

        function saveAdjudicator(data, id) {
            angular.extend(data, {adjudicator_id: id});
            return DataService.submitObj('Adjudicator', data);
        }

        function removeAdjudicator(idx){
            if (confirm('Are you sure?')) {
                DataService.deleteObj('Adjudicator', vm.adjudicators[idx].adjudicator_id).then(function() {    
                    vm.adjudicators.splice(idx, 1);
                })
                .catch(function(error) {
                    console.log(error);
                });
            }
            return false;
        }

        function newAdjudicator() {
            var newAdjudicator = {
                adjudicator_name: 'new'
            };
            return DataService.submitObj('Adjudicator', newAdjudicator).then(function(result) {
                vm.adjudicators.push(result);
            })
            .catch(function(error) {
                console.log(error);
            });      
        }

        function gotoAssignAdjudicators() {
            $state.go('manager.assignAdjudicators');
            vm.selectedDiscipline = null;
            loadDisciplines().then(function() {
                loadAdjudicators();
            })
            .catch(function(error) {
                console.log(error);
            });
        }

        function setSelectedDiscipline(discipline) {
            vm.selectedDiscipline = discipline;
        }

        function saveAssignment(num) {
            vm.selectedDiscipline.adjudicators[num].judgeAssignment_f_adjudicator_id = vm.selectedDiscipline.selectedAdjudicators[num].adjudicator_id;
            vm.selectedDiscipline.adjudicators[num].judgeAssignment_certification = 0;
            
            return DataService.submitObj('JudgeAssignment', vm.selectedDiscipline.adjudicators[num]);
        }


        // ******************************************* SCORING CRITERIA  *******************************************

        function gotoEditCriteria() {
            vm.selectedCriteriaGroup = null;
            $state.go('manager.editCriteria');
            loadCriteriaGroups();
        }

        function setSelectedCriteriaGroup(criteriaGroup) {
            vm.selectedCriteriaGroup = criteriaGroup;
            loadCriteria();
        }

        function saveCriteria(data, id) {
            angular.extend(data, {criteria_id: id});  // xeditable doesn't send id, so it comes separately
            return DataService.submitObj('Criteria', data);
        }



        // ******************************************* CERTIFICATIONS  *******************************************

        function gotoEditCertifications() {
            $state.go('manager.editCertifications');
            loadCertifications();
        }

        function showCertificationEditBox(cert) {
            cert.edit = true;
        }

        function saveCertificationEdits(cert) {
            DataService.submitObj('CertificationData', cert).then(function() {
                cert.edit = false;
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        
        


        // ******************************************* PRIVATE FUNCTIONS  *******************************************

        function activate() {
            if ($sessionStorage.manager) {
                vm.manager = $sessionStorage.manager;
                $state.go('manager.welcome');
            } else {
                vm.manager = {};
                $state.go('manager.login');
            }    
            vm.authMsg = '';
            vm.loggingIn = false;
        } 


        function createChart(finalData) {
            console.log(finalData);
            vm.width = (finalData.length * 38) + "px";
            vm.height = (finalData.length * 12) + "px";
            if (finalData.length * 12 > 600) {
                vm.height = "600px";
            }
            if (finalData.length < 20) {
                vm.width = "700px";
                vm.height = "400px";
            }
            
            var labels = [];
            var j1Rank = [];
            var j2Rank = [];
            var j3Rank = [];
            var j4Rank = [];
            var avgRank = [];
            var counter = 0;
            $.each(finalData, function(index, value) {
                counter++;
                labels.push(counter + " - " + value.candidate_osai_id);
                j1Rank.push(value.candidate_adjudicator_rank_1);
                j2Rank.push(value.candidate_adjudicator_rank_2);
                j3Rank.push(value.candidate_adjudicator_rank_3);
                if ( vm.selectedDiscipline.discipline_id == 23) {
                    j4Rank.push(value.candidate_adjudicator_rank_4);
                }
                avgRank.push(value.averageRank);
            });
            if ( vm.selectedDiscipline.discipline_id == 23) {
                // vm.series = ["Average", "Judge 1", "Judge 2", "Judge 3", "Judge 4"];
                vm.data = [
                    avgRank, j1Rank, j2Rank, j3Rank, j4Rank
                ];
                vm.labels = labels;
                vm.colors = ['#3A3F51', '#3333CC', '#EC2121', '#2F80E7', '#6AB46C' ];

                
                vm.datasetOverride = [
                    {fill: false},
                    {fill: false},
                    {fill: false},
                    {fill: false}, 
                    {fill: false}
                ];
            } else {
                // vm.series = ["Average", "Judge 1", "Judge 2", "Judge 3"];
                vm.data = [
                    avgRank, j1Rank, j2Rank, j3Rank
                ];
                vm.labels = labels;
                vm.colors = ['#3A3F51', '#3333CC', '#EC2121', '#2F80E7' ];

                
                vm.datasetOverride = [
                    {fill: false},
                    {fill: false},
                    {fill: false},
                    {fill: false}
                ];
            }

            vm.series = ["Average"];
            for(var i=0; i<vm.judgeNames.length; i++) {
                var thisJudge = vm.judgeNames[i];
                vm.series.push(thisJudge.adjudicator_name);
            }


            

            vm.options= {
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    labels: {
                        fontColor: 'rgb(100,100,100)'
                    },
                    position: 'left'
                },
                scales: {
                    xAxes:[{
                        scaleLabel: {
                            display: true,
                            labelString: 'Average Ranking',
                            fontColor: 'green'
                        }
                    }],
                    yAxes:[{
                        scaleLabel: {
                            display: true,
                            labelString: 'Assigned Rank',
                            fontColor: 'green'
                        }
                    }]
                }
                
            };

            // DataService.get('Discipline', vm.selectedDiscipline.discipline_id, 'judge_names', null, 'Could not load judge names').then(function(result) {
            //     // vm.series = ["Average"];
            //     // var name;
            //     // for(var i=0; i<result.length; i++) {
            //     //     var thisJudge = result[i];
            //     //     name = thisJudge.adjudicator_name ? thisJudge.adjudicator_name : 'Judge ' + (i+1);
            //     //     vm.series.push(name);
            //     // }
            // })
            // .catch(function(error) {
            //     console.log(error);
            // });
            
            
        }


        function loadAdjudicators() {
            return DataService.get('Manager', null, 'adjudicators', null, 'Could not load adjudicators').then(function(result) {
                vm.adjudicators = result;
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function loadDisciplines() {
            return DataService.get('Manager', null, 'disciplines', null, 'Could not load disciplines').then(function(result) {
                vm.disciplines = result;
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function loadProgress() {
            return DataService.get('Manager', null, 'progress', null, 'Could not load progress').then(function(result) {
                vm.progressDisciplines = result;
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function loadCertifications() {
            vm.loadingCertifications = true;
            return DataService.get('Certification', null, 'certifications', null ).then(function(result) {
                vm.certifications = result;
                vm.loadingCertifications = false;
            })
            .catch(function(error) {
                vm.loadingCertifications = false;
                console.log(error);
            });
        }

        function loadCriteriaGroups() {
            vm.loadingCriteriaGroups = true;
            return DataService.get('Manager', null, 'criteria_groups', null).then(function(result) {
                vm.criteriaGroups = result;
                vm.loadingCriteriaGroups = false;
                
            })
            .catch(function(error) {
                vm.loadingCriteriaGroups = false;
                console.log(error);
            });
        }


        function loadCriteria() {
            vm.loadingCriteria = true;
            return DataService.get('Manager', null, 'criteria_for_criteriaGroup', vm.selectedCriteriaGroup.criteriaGroup_id).then(function(result) {
                vm.criteria = result;
                vm.loadingCriteria = false;
            })
            .catch(function(error) {
                vm.loadingCriteria = false;
                console.log(error);
            });
        }


        function loadPhotographyCandidates() {
            return DataService.get('Manager', null, 'photography_candidates', null).then(function(response) {
                vm.photographyCandidates = response;
            })
            .catch(function(error) {
                console.log(error);
            });
        }


        function rankCandidates(discipline) {
            return DataService.action('Discipline', discipline.discipline_id, 'set_ordinals' );
        }

        function loadScores(discipline) {
            return DataService.action('Discipline', discipline.discipline_id, 'load_scores');
        }

        
        function checkPhotographyCandidates() {
            return DataService.get('Manager', null, 'photography_technical_confirmation');
        }


        function exportResults() {
            alert('Results will be exported.');
        }

        // ******************************************* SIMULATION  *******************************************

        function simulation() {
            if (!vm.simulationRunning) {
                SimulatorService.start();
                vm.simulationRunning = true;
            } else {
                SimulatorService.stop();
                vm.simulationRunning = false;
            }
        }


        

    }

   
})();